import(/* webpackMode: "eager" */ "/vercel/path0/components/animated-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/country-button-with-tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/dark-mode-selector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/logo-with-tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileMenu"] */ "/vercel/path0/components/mobile-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeColorSwitcher"] */ "/vercel/path0/components/theme-color-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/dk-flag.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/no-flag.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/se-flag.svg");
